import { Accordion, Box, rem, Title } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { Trans, useTranslation } from "next-i18next";
import Link from "next/link";
import { TicketAgeClassification } from "../TicketAgeClassification";

export function FaqAccordion({
  item,
  faqIndex,
}: {
  item: any;
  faqIndex?: number;
}): JSX.Element {
  const { t, i18n } = useTranslation(["faq"]);
  const items = item.contents || item;

  const handleDisplayTicketTable = (index: number) => {
    if (item.contents) {
      if (faqIndex === 0 && index === 3) {
        return true;
      }
    } else {
      if (index === 3) {
        return true;
      }
    }
  };

  return (
    <Accordion
      chevron={<IconChevronDown strokeWidth="1" />}
      chevronSize={rem(25)}
      mx="auto"
      w="100%"
      variant="separated"
      classNames={{
        item: "border-dark-5 data-[active]:bg-white bg-dark-4 mb-4",
        control: "rounded-lg px-4 py-0",
        panel: "!py-0",
      }}
      radius="md"
      multiple={true}
    >
      {items?.map((content: any, index: number) => (
        <Box key={index}>
          <Accordion.Item value={String(index)}>
            <Accordion.Control>
              <Title order={3} className="!text-dark-9 text-base font-normal">
                {content.question}
              </Title>
            </Accordion.Control>
            <Accordion.Panel>
              <Box className="text-dark-8">
                {content.answer?.map((line: any) => (
                  <Trans
                    key={line}
                    i18nKey={line}
                    components={{
                      list: <li />,
                      contact: (
                        <Link
                          className="underline underline-offset-4 decoration-1"
                          locale={i18n.language}
                          href="/contact"
                          shallow={true}
                        />
                      ),
                      ticket: (
                        <Link
                          className="underline underline-offset-4 decoration-1"
                          locale={i18n.language}
                          href="/guide/boarding"
                          shallow={true}
                        />
                      ),
                      change: (
                        <Link
                          className="underline underline-offset-4 decoration-1"
                          locale={i18n.language}
                          href="/guide/update/change"
                          shallow={true}
                        />
                      ),
                      lost: (
                        <Link
                          className="underline underline-offset-4 decoration-1"
                          locale={i18n.language}
                          href="/guide/update/lost-tickets"
                          shallow={true}
                        />
                      ),
                      cancel: (
                        <Link
                          className="underline underline-offset-4 decoration-1"
                          locale={i18n.language}
                          href="/guide/update/cancellation"
                          shallow={true}
                        />
                      ),
                    }}
                  />
                ))}
              </Box>
              {handleDisplayTicketTable(index) && <TicketAgeClassification />}
            </Accordion.Panel>
          </Accordion.Item>
        </Box>
      ))}
    </Accordion>
  );
}
