import { isProd, WITH_GLOBALNAVI } from "@/constants";
import { Box, Button } from "@mantine/core";
import {
  IconTrain,
  IconBus,
  IconCar,
  IconQrcode,
  IconWifi,
} from "@tabler/icons-react";
import Link from "next/link";
import { useRouter } from "next/router";

const getAffiliateCode = (query: Record<string, any>, id: string): string => {
  if (query.cid?.includes("jpnstory")) {
    return id === "esim" ? "jpn" : "jpnstory";
  }
  if (query.cid?.includes("flyingjapan")) {
    if (id === "esim" || id === "wifi") return "flying.ad";
    return "flyingjapan.ad";
  }
  return "";
};

const TabButton = ({
  children,
  href,
  id,
  isActive,
}: {
  children: React.ReactNode;
  href: string;
  id: string;
  isActive?: boolean;
}) => {
  const router = useRouter();
  const affiliateCode = getAffiliateCode(router.query, id);

  return (
    <Button
      variant="default"
      classNames={{
        root: `border-none rounded-none !border-solid border-r-0 border-t-0 border-l-0 border-b-4 text-white bg-transparent ${
          isActive ? "border-pink-7" : "border-transparent"
        } text-xs md:text-base h-full p-1.5`,
        label: "flex-col md:flex-row md:gap-2.5 justify-center",
      }}
      component={Link}
      href={`https://www${!isProd ? "-dev" : ""}.${href}${affiliateCode}`}
      target="_blank"
      rel="noopener"
    >
      {children}
    </Button>
  );
};

export default function GlobalNavi(): JSX.Element {
  const router = useRouter();

  return (
    <Box className="border-t-[3px] border-solid border-b-0 border-r-0 border-l-0 border-blue-8 w-full h-17">
      <Box className="mx-auto max-w-[1300px] flex flex-row items-center justify-between md:justify-center gap-1 md:gap-9 h-full">
        <TabButton
          id="shinkansen"
          isActive
          href="japan-bullettrain.com/ko?cid=japanbullettrain."
        >
          <IconTrain className="size-7 stroke-[1.5]" />
          신칸센
        </TabButton>
        <TabButton
          id="bus"
          href="japan-bus-tickets.com/ko?code=japanbustickets."
        >
          <IconBus className="size-7 stroke-[1.5]" />
          고속버스
        </TabButton>
        <TabButton id="taxi" href="airport-taxi.tokyo/kr?code=AirportTaxi.">
          <IconCar className="size-7 stroke-[1.5]" />
          리무진 택시
        </TabButton>
        <TabButton id="esim" href="japan-wireless.com/esim/kr?via=">
          <IconQrcode className="size-7 stroke-[1.5]" />
          이심
        </TabButton>
        {router.query.cid === WITH_GLOBALNAVI.flyingjapan && (
          <TabButton id="wifi" href="japan-wireless.com/kr?via=">
            <IconWifi className="size-7 stroke-[1.5]" />
            와이파이
          </TabButton>
        )}
      </Box>
    </Box>
  );
}
